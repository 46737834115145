import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  TimeOfDay: any;
};


export type Query = {
  __typename: 'Query';
  ticketProvider: TicketProvider;
  ticketUsageReport: TicketUsageReport;
};


export type QueryTicketUsageReportArgs = {
  from: Scalars['ISO8601DateTime'];
  to: Scalars['ISO8601DateTime'];
};

export type TicketProvider = {
  __typename: 'TicketProvider';
  closingTime: Scalars['TimeOfDay'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TicketUsageReport = {
  __typename: 'TicketUsageReport';
  items: Array<TicketUsageReportItem>;
  sumOfQuantity: Scalars['Int'];
};

export type TicketUsageReportItem = {
  __typename: 'TicketUsageReportItem';
  id: Scalars['ID'];
  productId: Scalars['ID'];
  productName: Scalars['String'];
  purchasedProductId: Scalars['ID'];
  quantity: Scalars['Int'];
  quantityDetails: Scalars['String'];
  ticketId: Scalars['ID'];
  ticketName: Scalars['String'];
  usedAt: Scalars['ISO8601DateTime'];
};


export type TicketProviderFieldsFragment = (
  { __typename: 'TicketProvider' }
  & Pick<TicketProvider, 'id' | 'name' | 'closingTime'>
);

export type ReportUsageDetailPageQueryVariables = Exact<{
  from: Scalars['ISO8601DateTime'];
  to: Scalars['ISO8601DateTime'];
}>;


export type ReportUsageDetailPageQuery = (
  { __typename: 'Query' }
  & { ticketUsageReport: (
    { __typename: 'TicketUsageReport' }
    & Pick<TicketUsageReport, 'sumOfQuantity'>
    & { items: Array<(
      { __typename: 'TicketUsageReportItem' }
      & Pick<TicketUsageReportItem, 'id' | 'productId' | 'productName' | 'ticketId' | 'ticketName' | 'usedAt' | 'quantity' | 'quantityDetails'>
    )> }
  ), ticketProvider: (
    { __typename: 'TicketProvider' }
    & TicketProviderFieldsFragment
  ) }
);

export type ReportUsagePageQueryVariables = Exact<{ [key: string]: never; }>;


export type ReportUsagePageQuery = (
  { __typename: 'Query' }
  & { ticketProvider: (
    { __typename: 'TicketProvider' }
    & TicketProviderFieldsFragment
  ) }
);

export type RightContentQueryVariables = Exact<{ [key: string]: never; }>;


export type RightContentQuery = (
  { __typename: 'Query' }
  & { ticketProvider: (
    { __typename: 'TicketProvider' }
    & TicketProviderFieldsFragment
  ) }
);

export const TicketProviderFieldsFragmentDoc = gql`
    fragment TicketProviderFields on TicketProvider {
  id
  name
  closingTime
}
    `;
export const ReportUsageDetailPageDocument = gql`
    query ReportUsageDetailPage($from: ISO8601DateTime!, $to: ISO8601DateTime!) {
  ticketUsageReport(from: $from, to: $to) {
    sumOfQuantity
    items {
      id
      productId
      productName
      ticketId
      ticketName
      usedAt
      quantity
      quantityDetails
    }
  }
  ticketProvider {
    ...TicketProviderFields
  }
}
    ${TicketProviderFieldsFragmentDoc}`;

/**
 * __useReportUsageDetailPageQuery__
 *
 * To run a query within a React component, call `useReportUsageDetailPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportUsageDetailPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportUsageDetailPageQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useReportUsageDetailPageQuery(baseOptions: Apollo.QueryHookOptions<ReportUsageDetailPageQuery, ReportUsageDetailPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportUsageDetailPageQuery, ReportUsageDetailPageQueryVariables>(ReportUsageDetailPageDocument, options);
      }
export function useReportUsageDetailPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportUsageDetailPageQuery, ReportUsageDetailPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportUsageDetailPageQuery, ReportUsageDetailPageQueryVariables>(ReportUsageDetailPageDocument, options);
        }
export type ReportUsageDetailPageQueryHookResult = ReturnType<typeof useReportUsageDetailPageQuery>;
export type ReportUsageDetailPageLazyQueryHookResult = ReturnType<typeof useReportUsageDetailPageLazyQuery>;
export type ReportUsageDetailPageQueryResult = Apollo.QueryResult<ReportUsageDetailPageQuery, ReportUsageDetailPageQueryVariables>;
export const ReportUsagePageDocument = gql`
    query ReportUsagePage {
  ticketProvider {
    ...TicketProviderFields
  }
}
    ${TicketProviderFieldsFragmentDoc}`;

/**
 * __useReportUsagePageQuery__
 *
 * To run a query within a React component, call `useReportUsagePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportUsagePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportUsagePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportUsagePageQuery(baseOptions?: Apollo.QueryHookOptions<ReportUsagePageQuery, ReportUsagePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportUsagePageQuery, ReportUsagePageQueryVariables>(ReportUsagePageDocument, options);
      }
export function useReportUsagePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportUsagePageQuery, ReportUsagePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportUsagePageQuery, ReportUsagePageQueryVariables>(ReportUsagePageDocument, options);
        }
export type ReportUsagePageQueryHookResult = ReturnType<typeof useReportUsagePageQuery>;
export type ReportUsagePageLazyQueryHookResult = ReturnType<typeof useReportUsagePageLazyQuery>;
export type ReportUsagePageQueryResult = Apollo.QueryResult<ReportUsagePageQuery, ReportUsagePageQueryVariables>;
export const RightContentDocument = gql`
    query RightContent {
  ticketProvider {
    ...TicketProviderFields
  }
}
    ${TicketProviderFieldsFragmentDoc}`;

/**
 * __useRightContentQuery__
 *
 * To run a query within a React component, call `useRightContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useRightContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRightContentQuery({
 *   variables: {
 *   },
 * });
 */
export function useRightContentQuery(baseOptions?: Apollo.QueryHookOptions<RightContentQuery, RightContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RightContentQuery, RightContentQueryVariables>(RightContentDocument, options);
      }
export function useRightContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RightContentQuery, RightContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RightContentQuery, RightContentQueryVariables>(RightContentDocument, options);
        }
export type RightContentQueryHookResult = ReturnType<typeof useRightContentQuery>;
export type RightContentLazyQueryHookResult = ReturnType<typeof useRightContentLazyQuery>;
export type RightContentQueryResult = Apollo.QueryResult<RightContentQuery, RightContentQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    