import { UserOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu, Space, Typography } from 'antd'
import { Auth } from 'aws-amplify'
import React, { useMemo } from 'react'

import { useRightContentQuery } from '../../generated/graphql'

type Props = {
  user: { email: string }
}

export const RightContent: React.FC<Props> = ({ user }) => {
  const { data } = useRightContentQuery()
  const ticketProvider = data?.ticketProvider

  const menu = useMemo(
    () => (
      <Menu>
        <Menu.Item onClick={() => Auth.signOut()}>ログアウト</Menu.Item>
      </Menu>
    ),
    []
  )

  return (
    <Space>
      <Typography>
        {user.email}｜{ticketProvider?.name || '...'}
      </Typography>
      <Dropdown overlay={menu}>
        <Button shape="circle" icon={<UserOutlined />} />
      </Dropdown>
    </Space>
  )
}
