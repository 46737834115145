import { BasicLayoutProps } from '@ant-design/pro-layout'
import { MenuDataItem } from '@ant-design/pro-layout/lib/typings'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useCallback } from 'react'

import { Footer } from './Footer'
import { RightContent } from './RightContent'

const AntdBasicLayout = dynamic(
  async () => (await import('@ant-design/pro-layout')).BasicLayout,
  { ssr: false }
)

const Logo: React.FC = () => <Image src="/logo.svg" width={32} height={32} />

type Props = Required<Pick<BasicLayoutProps, 'route' | 'loading'>> & {
  user: { email: string }
}

export const BasicLayout: React.FC<Props> = ({
  user,
  route,
  loading,
  children,
}) => {
  const router = useRouter()
  const handleMenuHeaderClick = useCallback(
    () => router.push('/reports/usage'),
    [router]
  )
  const handleMenuItemRender = useCallback(
    (options: MenuDataItem, element: React.ReactNode) => (
      <Link href={options.path!}>
        <a>{element}</a>
      </Link>
    ),
    []
  )
  const rightContentRender = useCallback(() => <RightContent user={user} />, [
    user,
  ])

  return (
    <AntdBasicLayout
      title="RYDE PASS"
      logo={Logo}
      route={route}
      loading={loading}
      navTheme="light"
      fixSiderbar
      onMenuHeaderClick={handleMenuHeaderClick}
      menuItemRender={handleMenuItemRender}
      rightContentRender={rightContentRender}
      footerRender={Footer}
      style={{ minHeight: '100vh' }}
    >
      {children}
    </AntdBasicLayout>
  )
}
